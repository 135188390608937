import {Navbar, TextInput, Button, Modal, Label, Select} from "flowbite-react";
import React, {useState} from "react";
import './App.css';

const Bubble = ({text, name, translation, onTranslate}) => {
  return <div className={`flex ${name === "You" ? "justify-end" : "justify-start"}`}>
    <div className={`p-3 max-w-[calc(80vw)]`}>
      <div className={`text-xs font-medium ${name === "You" ? "text-right" : "text-left"}`}>
        {name}
      </div>
      <div className={`p-3 ${name === "You" ? "bg-purple-300" : "bg-gray-300"} w-fitbox-border rounded-md text-lg`}>
        {(text || "").split("\n").map(x => <div>{x}</div>)}
      </div>
      {
        name != "You" && !translation
        ? <div className={`text-xs font-medium underline text-blue-500 cursor-pointer`} onClick={onTranslate}>
            Translate
          </div>
        : <></>
      }
      {
        translation
        ? <div className={`mt-2 p-3 ${name === "You" ? "bg-purple-300" : "bg-gray-300"} w-fitbox-border rounded-md text-lg`}>
            {(translation || "").split("\n").map(x => <div>{x}</div>)}
          </div>
        : <></>
      }
    </div>
  </div>
}

function App() {
  const [messages, setMessages] = useState([])
  const [curMsg, setCurMsg] = useState("")
  const [isModalOpen, setModalOpen] = useState(false)
  const [info, setInfo] = useState({
    "name": "John",
    "gender": "Male",
    "age": 25,
  })
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current = document.body;
  }, [])

  React.useEffect(() => {
    let userInfo = localStorage.getItem("user_info")
    if (userInfo) {
      setInfo(JSON.parse(userInfo))
    }

    let x = localStorage.getItem("messages")
    if (x) {
      setMessages(JSON.parse(x))
    }

    if (!userInfo) {
      setModalOpen(true)
    }
  }, [])

  React.useEffect(() => {
    if (messages.length) {
      localStorage.setItem("messages", JSON.stringify(messages))
    }
  }, [messages])

  const register = async () => {
    let response = await fetch("https://nichijou.tenshi.dev/api/register", {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(info)
    })
    let json = await response.json()
    setInfo(json.info)
    localStorage.setItem("user_info", JSON.stringify(json.info))
  }

  const sendMsg = async () => {
    const currentMsg = curMsg
    setMessages(x => ([...x, {
      "name": "You",
      "text": curMsg,
    }]))
    setCurMsg("")
    let response = await fetch("https://nichijou.tenshi.dev/api/chat", {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        "message": currentMsg,
        "session_id": info.session_id,
      })
    })

    let json = await response.json()
    setMessages(x => ([...x, {
      "name": "Mi-chan",
      "text": json.response,
    }]))
  }

  const translate = async (msg_id) => {
    const msg = messages[msg_id]
    let response = await fetch("https://nichijou.tenshi.dev/api/translate", {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        "message": msg.text,
        "session_id": `${info.session_id}`,
      })
    })

    let json = await response.json()

    setMessages(x => {
      x[msg_id] = {
        ...x[msg_id],
        translation: json.response,
      }

      return [...x]
    })
  }

  const clearHistory = () => {
    setMessages([])
    setInfo(x => ({...x, session_id: undefined}))
    register()
  }

  return (
    <div className="relative">
      <Modal show={isModalOpen} onClose={() => setModalOpen(false)} root={ref.current || undefined}>
        <Modal.Header>User Info</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="flex max-w-md flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="name1"
                    value="Name"
                  />
                </div>
                <TextInput
                  id="name1"
                  placeholder="John Doe"
                  key={"name"}
                  value={info.name}
                  onChange={(e) => setInfo(x => ({...x, name: e.target.value}))}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="age"
                    value="Age"
                  />
                </div>
                <TextInput
                  id="age"
                  placeholder="25"
                  required
                  type={"number"}
                  value={info.age}
                  onChange={(e) => setInfo(x => ({...x, age: e.target.value}))}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="gender"
                    value="Gender"
                  />
                </div>
                <Select
                  id="gender"
                  value={info.gender}
                  onChange={(e) => setInfo(x => ({...x, gender: e.target.value}))}
                >
                  <option>Male</option>
                  <option>Female</option>
                </Select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            setModalOpen(false)
            register()
          }}>Submit</Button>
          <Button color="warning" onClick={() => clearHistory()}>
            Clear history
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar
        fluid
        rounded
        className={"h-8"}
      >
        <Navbar.Brand>
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Nichijou PoC
        </span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link
            active
            href="#"
          >
            <p>
              Chat
            </p>
          </Navbar.Link>
          <Navbar.Link className={"cursor-pointer"} onClick={() => setModalOpen(true)}>
            Change Info
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
      <div className={"h-[calc(100svh-3.5rem)] max-h-[calc(100svh-3.5rem)] w-[calc(100vw)] bg-purple-100 mt-6 box-border"}>
        <div className={"h-[calc(100%-4rem)] w-full overflow-y-scroll"}>
          {
            messages.map((x, i) => <Bubble name={x.name} text={x.text} translation={x.translation} onTranslate={() => translate(i)}/> )
          }
        </div>
      </div>
      <form onSubmit={(e) => {
        e.preventDefault()
        sendMsg()
      }} className={"bottom-0 p-2 w-screen absolute flex justify-around gap-4"}>
        <TextInput className={"flex-1"} onChange={x => setCurMsg(x.target.value)} value={curMsg}/>
        <Button className={"flex-11"} type={"submit"}>Send</Button>
      </form>
    </div>
  );
}

export default App;
